// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["WJB6oR1qd", "OCRHFdWUA"];

const serializationHash = "framer-pj5Na"

const variantClassNames = {OCRHFdWUA: "framer-v-1iflmqd", WJB6oR1qd: "framer-v-wqscgr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "WJB6oR1qd", "Variant 2": "OCRHFdWUA"}

const getProps = ({blogTitleMobile, height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "WJB6oR1qd", WKdPAj_dP: blogTitleMobile ?? props.WKdPAj_dP ?? "Itcan lastest News title it might Take two lines."} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;blogTitleMobile?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKdPAj_dP, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WJB6oR1qd", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-pj5Na", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-wqscgr", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WJB6oR1qd"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({OCRHFdWUA: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FsdG9uZSBTZW1pIEJvbGQ=", "--framer-font-family": "\"Altone Semi Bold\", \"Altone Semi Bold Placeholder\", sans-serif", "--framer-font-size": "32px", "--framer-line-height": "36px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-512a643a-6a48-47c6-8641-27985ce400fb, rgb(32, 23, 71)))", "--framer-text-transform": "capitalize"}}>Itcan lastest News title it might Take two lines.</motion.p></React.Fragment>} className={"framer-1id27e3"} data-framer-name={"Itcan lastest News title it might Take two lines."} fonts={["CUSTOM;Altone Semi Bold"]} layoutDependency={layoutDependency} layoutId={"k6w8zfsuW"} style={{"--extracted-r6o4lv": "var(--token-512a643a-6a48-47c6-8641-27985ce400fb, rgb(32, 23, 71))", "--framer-paragraph-spacing": "0px"}} text={WKdPAj_dP} transformTemplate={transformTemplate} verticalAlignment={"center"} withExternalLayout {...addPropertyOverrides({OCRHFdWUA: {children: <React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FsdG9uZSBTZW1pIEJvbGQ=", "--framer-font-family": "\"Altone Semi Bold\", \"Altone Semi Bold Placeholder\", sans-serif", "--framer-font-size": "24px", "--framer-line-height": "36px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-512a643a-6a48-47c6-8641-27985ce400fb, rgb(32, 23, 71)))", "--framer-text-transform": "capitalize"}}>Itcan lastest News title it might Take two lines.</motion.p></React.Fragment>, transformTemplate: undefined}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-pj5Na [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pj5Na .framer-lidfdk { display: block; }", ".framer-pj5Na .framer-wqscgr { height: 72px; overflow: visible; position: relative; width: 702px; }", ".framer-pj5Na .framer-1id27e3 { flex: none; height: auto; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 50%; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }", ".framer-pj5Na.framer-v-1iflmqd .framer-wqscgr { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; justify-content: center; padding: 0px 0px 0px 0px; width: min-content; }", ".framer-pj5Na.framer-v-1iflmqd .framer-1id27e3 { left: unset; position: relative; right: unset; top: unset; width: 315px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-pj5Na.framer-v-1iflmqd .framer-wqscgr { gap: 0px; } .framer-pj5Na.framer-v-1iflmqd .framer-wqscgr > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-pj5Na.framer-v-1iflmqd .framer-wqscgr > :first-child { margin-left: 0px; } .framer-pj5Na.framer-v-1iflmqd .framer-wqscgr > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 72
 * @framerIntrinsicWidth 702
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"OCRHFdWUA":{"layout":["auto","fixed"]}}}
 * @framerVariables {"WKdPAj_dP":"blogTitleMobile"}
 * @framerImmutableVariables true
 */
const FramerkRIvgvDkO: React.ComponentType<Props> = withCSS(Component, css, "framer-pj5Na") as typeof Component;
export default FramerkRIvgvDkO;

FramerkRIvgvDkO.displayName = "Archive / Blog Title";

FramerkRIvgvDkO.defaultProps = {height: 72, width: 702};

addPropertyControls(FramerkRIvgvDkO, {variant: {options: ["WJB6oR1qd", "OCRHFdWUA"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, WKdPAj_dP: {defaultValue: "Itcan lastest News title it might Take two lines.", displayTextArea: false, title: "Blog Title-Mobile", type: ControlType.String}} as any)

addFonts(FramerkRIvgvDkO, [{family: "Altone Semi Bold", url: "https://framerusercontent.com/assets/nJFPnEaKCLCj7xhLNEGWAFpID0.woff2"}])